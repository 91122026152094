import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">展覽論壇精華節錄</h2>
          <h1 className="main_title">看見困難在哪裡，大家一起翻越那座山</h1>
        </div>


        <article className="page_content">
{/* 
          <section className="row_area_s">
            <div className="column_3">
            </div>
          </section> */}

          <section className="row_area_s">
            <div className="column_5">
              <figure className="figure">
                <img src={require("../../images/blog/post_4.jpg")} alt="看見困難在哪裡，大家一起翻越那座山"/>
              </figure>
              <div className="context">
                <p><i>與談人（依出現順序）：吳慧貞、林書豪、沈伯丞、賴映如、方彥翔、王增榮、吳牧青、何震寰、蘇民、劉慧媛、蔡佳芬、林芝羽</i></p>
                <br/>
                <p><strong>吳慧貞：</strong>很開心跟社會各藝文界的朋友，以及社區的朋友討論什麼樣的公共藝術，才符合現今台北文化情境的需求？我們不是要找一個大論述解決一切，而是在這段期間盡量聆聽，讓更多有天份的人加入討論。目前40個社宅基地，有5個在帝門藝術基金會的擘劃之下，公共藝術已經逐步完成。但我們還是得思考，如何面對剩下的35處基地？</p>
                <p>在由禾磊藝術，擔任台北市公共藝術前期規劃總策展的這件案子的最後，我們希望透過藝術概念的嘗試操練，也就是這場展覽「Home to All：我們也可以這樣生活」，來探討一些可能性。下一個generation的聲音很重要，需要不同策略面的思考，所以我們邀請四位年輕策展人：景觀設計師賴映如，高美館策展人方彥翔，具多年實務和學術歷練的沈伯丞，以及在正濱漁港有深度進駐經驗的林書豪。他們從各自的領域來出發探討，台北居住意見如此分歧、信任又如此脆弱的現況下，社宅公共藝術如何可能？重點不在藝術形式，而希望回歸居住本質，凝聚一些想法。以下請四位分別向在座各位進行說明。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s">
            <div className="column_6">
              <div className="context">
                <p><strong>林書豪：</strong>這次策展提出「駐村編輯」的概念。以編輯的角度來說，藝術只是過程，最主要是透過這過程滾動大家參與的機會。編輯力就是組織掌握能力。我們這次以剝皮寮街區作為核心，在展覽中聚焦三個面向：「田野調查」、「職人黃頁」、「民眾共編」。「田野調查」是為每個地方找定位，例如在龍山寺或剝皮寮街區，透過社區的引動或策展空間，替每個空間重新下定義。「職人黃頁」希望將社宅民眾拉進來合作，設想在地的專業人才，未來怎麼運用，這是非常重要的社群網絡。而「民眾共編」則是除了我們內部在做這些事情之外，外邊資訊的累積和傳遞，也要透過開放性平台執行。</p>
                <p>幾個月前，我們在萬華剝皮寮一帶進行職人在地走讀。大家可以看到我們邀請在萬華社區小學工作的德君和子鈞，以及進駐新富町市場的冠蓉，拉出三條因應他們本來的專業的主題路線。每場走讀結束後會舉辦小型工作坊，對走讀進行回饋和累積。可以看到藝術作為中介，終點是和社區連結，醞釀自由精神和公民參與的可能性。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s">
            <div className="column_6">
              <div className="context">
                <p><strong>沈伯丞：</strong>我曾在中經院工作過一段日子，當時看了以色列集合農莊的報告，它從建國以來就一直存在，所有成果都共享，而這種集合農莊的人口，始終佔總人口的百分之二。這是很有趣的數字，也許一百個人中就有兩個人，或一個人一生就是願意拿百分之二與他人分享，完全不需理由或回報。百分之二前面加「1」，就變1.02。1.02的100次方很可觀，很多人能因此得到幫助，我就從這禮物經濟的角度來想公共藝術。</p>
                <p>我的題目是「成為我的繆思，獻給他的禮物」。找林立青，是因為「做工的人」對揭露社會底層有很大的震撼力，他深刻地凝視這些草民，也對萬華超級熟，知道默默在做百分之二的努力的那群人，因此請他寫他們的故事。孟娟的情況比較特殊，除了美國魏絲貝斯的計畫，還有新的計劃在進行，而我更在意那個新計畫，就是台北的「仁愛之家」。她關注高齡者，想追蹤並留影紀錄，也在仁愛之家認識所謂百分之二純粹奉獻的人。但因為新冠肺炎的疫情讓這計畫停擺，只好拿魏斯貝斯計畫替代。藝術公寓這種為了提供給藝術家而存在的公寓，某程度上跟社宅和我談論的禮物有關。這兩位創作者帶我們看見如何透過藝術想像力，把社群裡每個人串連起來。1.02的一百次方或一萬次方很大，而這是為何我從禮物經濟談到策展的原因。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s">
            <div className="column_6">
              <div className="context">
                <p><strong>賴映如：</strong>我本身是一個景觀設計師，所以從場域出發，因為社宅一定有景觀開放空間。那為什麼養植物也是公共藝術？我們去過興隆社宅的頂樓，住戶剛好在採菜，我們互不認識，但大家在聊天的過程中很快就破冰了，從這經驗來看，不管什麼背景的人，植物都是一個容易跟人開啟對話的乾淨媒介。我也有很多朋友，養植物跟養小孩一樣，平常都沒去過他家，但朋友出國前卻把鑰匙給我，要我去他家幫忙顧植物！說什麼貓派狗派，其實我們都是植物派啊。</p>
                <p>這次在線上的策展有兩部份。第一個是小Q語錄，基於我平常講話常跟植物有關，我就從自己的小Q語錄中，擷取幾段比較有趣的內容放上去。第二部份則是五集影片，像其中有兩集是教大家照顧植物寵物，所以喊出「澆水澆的好，人生沒煩惱」的口號。很多人叫我植物醫生，但人類沒辦法當植物醫生，我們不能醫治植物，人類跟植物是互相照顧的。</p>
                <p>我常跟大家說，你等公車時，有沒有跟路邊的行道樹說過話？許多公共議題其實跟植物相關，要從整體城市的角度去看，畢竟人類是生態圈的一部份。我們希望大家從社宅的公共空間、從養的植物開始，對自然產生好奇。森林健康，海就健康，森林的養份會回到海裡，而中間這段就是我們居住的都市。植物議題可以很簡單，回到人與人的相處，再把人帶回自然的想像。希望大家都在家養一隻植物跟你說說話，一起變成植物派！</p>
              </div>
            </div>
          </section>

          <section className="row_area_s">
            <div className="column_6">
              <div className="context">
                <p><strong>方彥翔：</strong>這次因為策展，和幾位志願者做訪談，談他們跟社區的關係，以及如何思考個人和群體的生活狀態。其中一位青創戶，因青創計畫進駐健康公宅，單身的她嘗試融入社區、在社區商店打工，喚起社區跟環境的記憶連結。另外一位居民，認為社區居住最重要的是安全感，因為她行動不便，算身心障礙住戶，工作地點又離社宅很遠，必須每天穿梭城市。最後還有興隆社宅的海小姐，在公務系統裡做公共環境綠化的工作，也在社區裡進行環境綠化，對她來說最重要就是社區公共空間。</p>
                <p>我花很多時間在這些案例上，並得到一些回饋，去想像集體生活計畫能夠是怎樣？最有名的相關計畫，是羅蘭巴特（Roland Barthes）的修道院。羅蘭巴特從文學性去講個人節奏和共同生活的關係。如何在共同生活裡有個人節奏？必須建立在飲食、床鋪大小、社交距離的維持，另外建築物本身也設計出特定的距離。他探討的是個人編碼怎麼進入社會，如同個人在社區裡的關係，或像公共藝術如何介入社區。另外，像加藤翼在311大地震後進行的社區凝聚計畫，則討論公共藝術怎麼透過臨時社群進入社區。個人和團體的狀態如何相互生成，著眼在臨時社群如何被喚醒。</p>
                <p>我的策展並非把個人展演化，而是透過此種觀看，談論為何當代生活的私密性被取消。有一種無可承受的親密性，就是暴力、恐懼、和傷害。因為私密性被取消所以這些事被忽視。策展是思考怎麼再利用與自己私密對話的方法，重新觀看家居勞動、被隱藏的暴力。</p>
                <p>雖說身心障礙、外配等個體戶乍看很難與外界連結，但社區還是有很多臨時性的集結。譬如媽媽們用的line群組，比其它社區工作者的私密群組還多。這種私密連結不是一種絕對的狀態，而是非常自發性的。社區中有許多內在的動力，在我的想像中，不一定需要長久性或建置性的方法，而可以用不同的方式介入。都市學提到如何維持都市的複雜性，我希望社宅的公共藝術可以維持社區中的複雜性。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s">
            <div className="column_6">
              <div className="context">
                <p><strong>王增榮：</strong>我猜測總策展人（吳慧貞）想做一個變革，因為公共藝術長期以來都只是雕像，而總策展人找來四位策展人，感覺是看中他們各自的獨特性。今天聽了四位策展人的想法，第一位是做歷史的工作，第二位環繞交換、人際關係。景觀小姐談到植栽，已經拉開人和人之間的關係，來到人和自然的關係。而最後一位談的東西很複雜，不是談人和人之間，而是回到一個單獨的狀態，他企圖心太大，要看他的文章才行。每一位的內容，對我都有啟發。</p>
                <p>四位策展人的觀念，把我們拉到深遠的歷史、拉到人群交流、談到植栽和世界的整體狀態，以及個人的狀態。這有沒有可能是革命？透過總策展把他們像這樣鋪陳到一個想像層級，之後參與正式公共藝術甄選的人，就能意識藝術並非那麼侷限，進而擴張對藝術的想像。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s">
            <div className="column_6">
              <div className="context">
                <p><strong>吳牧青：</strong>我先提一個無關的事情。最近台藝大第二校區，內政部預計做成社會住宅，同時也作為培育藝術家的工作室空間，這會發生雙方如何共處的問題。其實相對於一般社會大眾，藝術界對社宅非常關注，當代藝術不只關心藝術本身。像這樣空間的資源交換，是否能成為藝術工作者一個有效的禮物？</p>
                <p>剛好策展人提的題目，可以讓我們思考為什麼需要社會住宅。彥翔提單人部落，有點像和傳統家庭之間切割的兩難。你申請社宅必須直系親屬沒房子，可能他們在其它地方有房子，因為生活習慣未必住一起，但你不是他們的家庭成員嗎？家庭成員的定義是什麼？社宅和當代家庭已自成悖論，又限定中低收入戶40％先申請，其實有蠻多問題。</p>
                <p>慧貞十多年前就推新形式的公共藝術，其實走在很前面，包括定義舊社宅和公共藝術之間的關係。蘆州的建案鼓勵若有公共藝術就多給5%容積。但當公共藝術成為換取容積獎勵的籌碼，背後是誰去發動的就很重要，很多做到後來被說根本不是在做藝術。我們也發現，需要的已經不是巨型裝置，而是藝術有沒有可能，是無法透過消費取得的交往和服務關係？怎樣置入這種都市規劃設想不到的思維，這是現階段做公共藝術策展比較積極的意義。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s">
            <div className="column_6">
              <div className="context">
                <p><strong>何震寰：</strong>我本身是建築師，建築師和公共藝術對立由來已久，而解藥我覺得是走向關係美學。我不覺得需要在空間中做一個供人膜拜的物件。</p>
                <p>我雖沒住過社宅，但我買過房子，最後住在一起的狀態是被市場決定的，但大家心裡認為住在一起，是循序漸進，還是一夕之間發生的？原始社會要構成聚落，中間有營造的過程，大家身體要勞動造房子；但今天社宅是透過抽籤、政府治理術，一群人突然變成鄰居，這事情的心理層面需要機制去介入，而公共藝術可以針對這部份去作為。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s">
            <div className="column_6">
              <div className="context">
                <p><strong>蘇民：前</strong>輩已把公共藝術梳理完，我就不多做贅述。我提讀過資料的想法。</p>
                <p>現在是40處公共藝術基地，在尋找迎向未來的切入點。依我觀察，這40個地方需要更多元的服務，更多觸及住宅及周遭社區，這樣能卯釘這些居民彼此關係依存的可能。我認為策展人引領居住人發動關鍵事件，把公共藝術做起灶的動作，專業上不是課題，但裡面人的互存關係比較難讓你們的專業介入，這像政治問題，或像策展人的反思。</p>
                <p>我發現現在的居民很想參與公共事務，這凸顯過去我們在城鄉的累積，居民有公共概念，只是沒有更成熟的專業管理。但這也有矛盾，當你有這樣的機會時，又必須有像建築師這樣的專業才能去吸納。就像何震寰老師講建築師和藝術家的爭論，既要有政治上的柔軟度，又要有非常專業的建築本事。</p>
                <p>民間建案的特點是銷售結束，這事就和建商無關。但社宅的所有人是政府，五年、十年後這裡面後續還有沒有互動關係可以做？搭配前面綜合性的規劃，更可以彈性運用資源。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s">
            <div className="column_6">
              <div className="context">
                <p><strong>劉惠媛：</strong>我想從典藏的觀點，來看如何做這場數位展覽的保存與管理。這四位策展人共同的特質，是好奇心都很強。我們常討論策展這件事，從Curator，博物館的保管、研究、治療、維護、管理，到關心這件事情的curation，其實變化非常快，那如何很快地過濾、判斷、收集，然後統整，用擅長的方式來傳達，這些都令人驚喜，我看完覺得收穫滿大的。另一個就是微策展，用雲端和大家分享，一定是未來的趨勢。很期待是美好的開始，相信這中間還有很多可能性。</p>
                <p><strong>蔡佳芬：</strong>過去在文化局，我有參與過幾次的公共藝術審查會，會覺得藝術家、居民跟公部門之間的三角關係需要去拉近。尤其藝術家對在地最陌生，卻要創造出對這個地點最有啟發性的作品，這非常困難，因為需要很多時間。所以公部門有沒有可能承載售後保固這件事？以現在機制來說，公部門會有負擔，但有沒有可能透過怎樣的設置？或許民眾也需要付出，這要更多的討論。</p>
                <p><strong>林芝羽：</strong>我們做社宅規劃時，居民對「社宅會發生在我家旁邊」的這件事非常抗拒，等到有幾棟社宅真的蓋出來後，觀念才改變。所以公共藝術是一種和解的手段，建築師沒辦法做到跟周邊的文化歷史和居民協調，應該要有更軟性的方法介入。這次計畫是前期規劃裡最後的一個實驗。四位策展人幫我們想像用很軟性的手法來介入。</p>
                <p>台北市社宅只租不售，所以後續的維管都在都發局底下去做。現在40個公共藝術的分包，不是一個個基地單獨分包，而是把相近區塊做一個打包式的分包。即將執行的是信義、文山、和萬華，寫計畫書之前，四個萬華里長都跑來，地方的很多議員也跑來，跟我們講說要什麼。在政治這塊，我們會盡所能去保護它。大家都說要民眾參與，但這是一種很可怕的民眾參與，公共藝術的錢必須用在公共藝術上，不要用任何名目做其它事。</p>
                <p>至於社宅的機制，我們有3+3的設計。住進來三年若表現良好可以再多延長三年。所以每個基地發包之後，還要撥一筆錢，之後讓更多軟性的公共藝術去產生。若發包的金額過大，裡面可以設計一種「design team」的徵選方法，在這一個包裡讓更多年輕人生產創意作品，讓更多人參與社宅公共藝術的機制。</p>
              </div>
            </div>
          </section>


          <section className="row_area_s">
            <div className="column_6">
              <div className="context">
                <p><strong>吳慧貞：</strong>如何在自治的概念下訓練承擔責任跟義務，這很重要。將來公共藝術有沒有可能變成社區藝術的基金，設計一套機制，產生經濟上可以支持永續運作循環的可能？這可能也很重要。<br/>原本40處社宅基地，目前被分成11塊發包公共藝術。當初覺得要做區域整合，因為社宅分布在東南西北不同地區，加上生活範圍、都會生活的性質很難統合，所以才希望操作上以區域劃分，這樣整合交通、鄰里、社區生活機能和文化脈絡，來可以形塑一個都會文化生活圈。</p>
                <p><strong>吳牧青：</strong>台灣一直延用戶籍政策，那是否可能藉由社宅去產生變革？里長不歡迎社宅，是因為附近設籍的住民反對40%中低收入戶做鄰居，怕影響房價，如何不讓這件事變成另一種社會隔離的手段？比如主張在這裡面廢除戶籍？讓社宅居民參與當地政治，這樣社區編輯才有用，有編輯權但沒政權是不可能的。像書豪在基隆蹲點，一定有更多社區工作的方法。</p>
                <p><strong>林書豪：</strong>我的戶籍不在基隆。政治性其實可以用某些技巧化解，就是前期的田調，過程中的陪伴，到相互的信任關係。不然很多里長會認為外來者是。而戶籍存不存在只是一個Icon。重點在於人與人之間的信任感是否建構起來，這需要時間而且很挑戰。</p>
                <p><strong>林芝羽：</strong>社宅如果一戶是一到三人，那光廣慈園區就可以成立一個里，那個票數非常驚人，會完全改變里長的政治方向。里長原本以為應該要幫周邊爭取，因為那是他的票倉，現在爭取社宅認同感反而變成里長的重要任務。</p>
              </div>
            </div>
          </section>


          <section className="row_area_s">
            <div className="column_6">
              <div className="context">
                <p><strong>吳慧貞：</strong>以安康平宅為例，就住進30%低收入戶的比例。能否讓他們不被標籤化，並跟其他人混居？裡面又有相當比例的青創，青創是希望讓申請人提一個特殊的回饋計畫，為社區帶來正向影響，比如提供法律諮詢、教小朋友打棒球，若被選上可以優先入住。所以比較有趣的是，雖然擔心年輕人和低收入戶會不會被社會標籤化，可是當量大到一定程度時，它反而會成為一個政治板塊而具影響力。</p>
                <p>只是社宅的管理也很困難，還有即便同樣是弱勢，也不一定心裡認為是同一國。誰會排擠誰，誰能包容誰，錯綜複雜。我十幾年前做公共藝術，被說20世紀現代建築想建造的烏托邦早崩毀了，那現在的公共藝術是不是想建造另一個烏托邦？所以我常常提醒自己，應該要認真把眼睛擦亮，看清楚真實的生活現況。</p>
                <p>20年的策展經驗讓我學到一件事情，藝術沒辦法置外於社會和政治。面對公眾和情境，沒有一件事情可以被單一解決。今天謝謝四位策展人與我一起半年的努力，剛提到這些複雜性，在社會、創作、個人之間存在許多矛盾，但又必須共存，如同方彥翔在公共藝術為什麼談私密性和孤獨？這種雙向的路徑跟交融非常迷離，年輕人雖沒有太多經驗，但他看到其中的複雜性。也謝謝大王老師（王增榮）和吳牧青這一路的協助和理解。除了這檔展覽，這案子許多顧問都很關注，龔書章老師就說，我們不要把跨領域、階層當口號，必須看清真正要克服的事情是麼，大家真的看到那座山然後一起各展本領去跨越它。這一年半做的事，正是努力看清楚橫在我們之間的山是什麼，以及衡量跨過去的路徑。謝謝大家今天的參與。</p>
              </div>
            </div>
          </section>


          <section className="row_area_s">
            <div className="column_5">
              <div className="context">
                
              </div>
            </div>
          </section>


          <section className="row_area_s">
            <div className="column_5">
              <div className="context">
                
              </div>
            </div>
          </section>


          <section className="row_area_s">
            <div className="column_5">
              <div className="context">
                
              </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_5" className="next_page">
            <div className="next_title">社宅公共藝術計劃命名活動</div>
            <div className="next_owner">LOGIN@_</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
